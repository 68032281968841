<template>
  <div class="view-login auth mb-5">
    <div class="container">
      <div class="row">
        <div class="col mx-auto">
          <div class="row mb-3">
            <div class="col-5 mx-auto">
              <img class="img-fluid" src="@/assets/images/logo.png" />
            </div>
          </div>
          <form class="card" @submit.prevent="submit">
            <div class="card-body" v-if="!success">
              <h4 class="mb-4">Criar nova senha</h4>

              <form-group
                v-model="password"
                id="password"
                label=" "
                icon="icon-password"
                type="password"
                placeholder="Digite a nova senha"
                :errors="errors.password"
              />

              <form-group
                v-model="password_confirm"
                id="password_confirm"
                icon="icon-password"
                label=" "
                type="password"
                placeholder="Digite a senha novamente"
                :errors="errors.password_confirm"
              />

              <div class="text-center py-3">
                <button
                  class="btn btn-primary btn-fixed-size"
                  type="submit"
                  :disabled="loading"
                >
                  <loading :show="loading">Salvar</loading>
                </button>
              </div>
            </div>
            <div class="card-body py-5" v-else>
              <h5 class="text-center text-success my-4">
                Senha alterada com sucesso
              </h5>
              <div class="text-center">
                <router-link
                  class="btn btn-primary btn-fixed-size"
                  :to="{ name: 'login' }"
                >
                  Fazer login
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      password: "",
      password_confirm: "",
      errors: {
        password: null,
        password_confirm: null,
        non_field_errors: null
      },
      loading: false,
      success: false
    };
  },
  methods: {
    submit() {
      this.$message.hide();

      if (!this.password) {
        this.errors.password = ["Digite a senha"];
        this.$message.error("Digite a nova senha");
        return;
      }

      if (!this.password_confirm) {
        this.errors.password_confirm = ["Digite a confirmação da nova senha"];
        this.$message.error("Digite a confirmação da nova senha");
        return;
      }

      if (this.password !== this.password_confirm) {
        this.errors.password = ["Senhas não conferem"];
        this.errors.password_confirm = ["Senhas não conferem"];
        this.$message.error("Senha e confirmação de senha estão divergentes");
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("user/resetPassword", {
          password: this.password,
          token: this.$route.query.token
        })
        .then(() => {
          this.success = true;
        })
        .catch(error => {
          if (error.response && error.response.data.detail) {
            this.$message.error(error.response.data.detail);
          }

          if (error.response && error.response.data) {
            if (error.response.data.message) {
              this.$message.error(error.response.data.message);
              return;
            }

            let msg = "";
            for (key in error.response.data) {
              msg += error.response.data[key].join(" ");
            }
            this.$message.error(msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-login {
  width: 100%;
}
</style>
